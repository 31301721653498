import React, { useEffect, useState } from "react";
import Stack from "@mui/material/Stack";
import NetInfo from "@react-native-community/netinfo";
import { Text } from "react-native";
import { SelectDoctorNew } from "./src/components/CustomMenuSelectDoctor";
import { QuotesByDoctor } from "./src/screens/Quotes/QuotesByDoctor";
import { NavigationContainer } from "@react-navigation/native";
import { createNativeStackNavigator } from "@react-navigation/native-stack";
import { FinalQuote } from "./src/screens/Quotes/FinalQuote";
import { LoginT } from "./src/screens/Login";
import { ROUTES } from "./src/common/routes";
import { LinearGradient } from "expo-linear-gradient";
import { AppContext } from "./src/context/AppContext";
import { MaterialIcons } from "@expo/vector-icons";
import { clean, uploadAllData } from "./src/common/async_data";
import { EditQuote } from "./src/screens/Quotes/EditQuote";
import { HomeTest } from "./src/screens/Home";
import { QuoteList } from "./src/screens/Quotes/QuoteList";

const config = { dependencies: { "linear-gradient": LinearGradient } };
const stack = createNativeStackNavigator();

// "jsEngine": "hermes",
const App = () => {
  const [names, setNames] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [network, setNetwork] = useState<boolean>(false);

  useEffect(() => {
    const unsubscribe = NetInfo.addEventListener((state) => {
      setNetwork(state.isInternetReachable || false);
      if (state.isInternetReachable) uploadAllData();
    });
    return unsubscribe;
  }, []);

  return (
    <LinearGradient colors={["#671E75", "#A12CB8"]} style={{ flex: 1 }}>
      <AppContext.Provider
        value={{
          names,
          email,
          network,
          setNames,
          setEmail,
          setNetwork,
        }}
      >
        <NavigationContainer>
          <stack.Navigator
            initialRouteName={ROUTES.LOGIN}
            screenOptions={({ navigation }) => ({
              headerRight: () => (
                <Stack spacing={2} mr={5} direction={"row"}>
                  <Stack spacing={1} direction={"row"}>
                    <MaterialIcons name={"person"} size={24} color={"white"} />
                    <Text style={{ color: "white", fontSize: 18 }}>{names}</Text>
                  </Stack>
                  <Stack spacing={0} direction={"row"}>
                    <MaterialIcons name="home" size={24} color="white" onPress={() => navigation.navigate("Inicio")} />
                    <Text style={{ color: "white", fontSize: 18 }}> Home</Text>
                  </Stack>
                </Stack>
              ),
              headerStyle: {
                backgroundColor: "#671E75",
              },
              headerShadowVisible: false,
              headerTintColor: "#FFF",
              headerTitleStyle: {
                fontWeight: "bold",
              },
            })}
          >
            {/* <Stack.Screen
                            name={ROUTES.LOGIN}
                            component={Login}
                            options={{
                                headerShown: false,
                                gestureEnabled: false,
                            }}
                        /> */}
            <stack.Screen
              name={ROUTES.LOGIN}
              component={LoginT}
              options={{
                headerShown: false,
                gestureEnabled: false,
              }}
            />
            <stack.Screen
              name={ROUTES.QUOTE_SUMMARY}
              component={FinalQuote}
              options={{
                gestureEnabled: false,
              }}
            />
            {/* <Stack.Screen
                            name={ROUTES.EDIT_QUOTE}
                            component={EditQuote}
                            options={{
                                headerBackVisible: false,
                                gestureEnabled: false,
                            }}
                        /> */}
            <stack.Screen
              name={ROUTES.EDIT_QUOTE}
              component={EditQuote}
              options={{
                headerBackVisible: false,
                gestureEnabled: false,
              }}
            />
            <stack.Screen name={ROUTES.HOME_TEST} component={HomeTest}></stack.Screen>

            <stack.Screen name={ROUTES.DOCTOR_QUOTES} component={QuotesByDoctor} />
            <stack.Group>
              <stack.Screen
                name={ROUTES.HOME}
                component={HomeTest}
                options={{
                  headerShown: false,
                  gestureEnabled: false,
                }}
              />
              <stack.Screen name={ROUTES.FIND_DOCTOR} component={SelectDoctorNew} />
              <stack.Screen name={ROUTES.QUOTE_LIST} component={QuoteList} />
            </stack.Group>
          </stack.Navigator>
        </NavigationContainer>
      </AppContext.Provider>
    </LinearGradient>
  );
};

export default App;
